@import '../../variables';

.promoSlider {
    display: grid;
    grid-template-rows: 1fr auto;
    grid-template-columns: 1fr auto 1fr;
    -webkit-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
    row-gap: 12px;

    // &_expanded {
    //     grid-template-columns: 1fr minmax(84vh, 618px) 1fr;
    // }
}

.promoSlider-arrowLeft {
    grid-column: 1/2;
    align-self: center;
    justify-self: flex-end;

    display: flex;
    align-items: center;

    cursor: pointer;

    &_disabled {
        cursor: auto;
        & svg path {
            fill: darken($bgc-light, 75%)
        }
    }
}

.promoSlider-sliderWindow {
    overflow-x: hidden;
    grid-column: 2/3;
    justify-self: center;
    flex: 0 2 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.promoSlider-arrowRight {
    grid-column: 3/-1;
    align-self: center;
    flex: 2 0 auto;

    display: flex;
    align-items: center;

    cursor: pointer;

    &_disabled {
        cursor: auto;
        & svg path {
            fill: darken($bgc-light, 75%)
        }
    }
}

.promoSlider-pagination {
    grid-column: 1 / -1;
    grid-row: 2/-1;
    justify-self: center;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    min-height: 12px;
}

.promoSlider-paginationItem {
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    cursor: pointer;
}

.promoSlider-innerWrapper {
    display: flex;
    align-items: center;
}

.promoSlider-slide {
    flex-shrink: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    max-width: 100%;
    cursor: pointer;

    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;

    & img {
        object-fit: contain;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
    }

    &_mobile img {
        max-height: 282px;
    }

    &_expanded img {
        height: 66vh;
        max-height: 618px;
    }
}
