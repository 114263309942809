.tab {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    cursor: pointer;
}

.tab-activeElement {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 8px;
    min-width: 8px;


    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}
