@import '../../variables';

.notFound {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: calc($header-height + 60px) 0 80px;
    min-height: 60vh;
    text-align: center;
}

.notFound-bigLetters {
    margin-bottom: 8px;

    font-family: $ff-title;
    font-style: normal;
    font-weight: 900;
    font-size: 280px;
    line-height: 1;
    color: $fc-shaded-grey;
}

.notFound-title {
    margin-bottom: 24px;
    font-family: $ff-title;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
    color: $fc-orange;
}

.notFound-clarification {
    margin-bottom: 32px;
    font-family: $ff-regular;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1;
    color: $fc-dark;
}

.notFound-link {
    display: block;
    font-family: $ff-title;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 2.62;
    text-transform: uppercase;
    color: $fc-orange;
}
