$header-height: 92px;
$footer-height: 22vh;
$page-padding: 20px;

$ff-title: 'Roboto Slab', 'Times New Roman', serif;
$ff-regular: 'Roboto', 'Arial', sans-serif;

$fw-title: 700;
$fw-regular: 300;

$fc-dark: #090909;
$fc-light: #ffffff;
$fc-orange: #ffb800;
$fc-red: #ff0000;
$fc-shaded-grey: #f6f6f6;
$fc-error-input: #FC5C5C;

$bgc-dark: rgba(9, 9, 9, 1);
$bgc-transparent: rgba(9, 9, 9, 0);
$bgc-overlay: rgba(9, 9, 9, 0.9);
$bgc-light-grey: #f8f8f8;
$bgc-light: #ffffff;
$bgc-orange: #ffb800;
$bgc-orange-hovered: #c54301;

$border-grey: 1px solid #cecece;
$border-red: 1px solid #ff0000;

$max-content-width: 960px;
