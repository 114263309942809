@import '../../variables';

.button {
    display: flex;
    align-items: center;
    gap: 14px;

    height: 52px;
    padding-left: 20px;
    padding-right: 24px;

    font-family: $ff-regular;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.19;
    text-align: center;
    color: $fc-light;

    border: none;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;
    background-color: $bgc-orange;
    -webkit-transition: all linear 0.3s;
    -moz-transition: all linear 0.3s;
    -ms-transition: all linear 0.3s;
    -o-transition: all linear 0.3s;
    transition: all linear 0.3s;

    cursor: pointer;

    &:hover {
        background-color: $bgc-orange-hovered;
    }
}
