@import '../../variables';

.promo {
    height: 843px;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $bgc-dark;
}

.promo-contentWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 202px;
    padding-bottom: 88px;
}

.promo-row {
    height: 377px;
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: 0 auto;
    padding-left: 44px;
}

.promo-textGroup {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    padding-right: 40px;
    box-sizing: border-box;
}

.promo-text {
    margin-bottom: 20px;
    color: $fc-light;
}

.promo-title {
    margin-bottom: 24px;

    font-family: $ff-title;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 1.31;
}

.promo-subtitle {
    display: block;
    margin-bottom: 12px;

    font-family: $ff-regular;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.15;
}

.promo-description {
    font-family: $ff-regular;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.75;
}

.promo-links {
    display: flex;
    align-items: center;
    gap: 16px;

    & a svg {
        vertical-align: middle;
    }
}

.promo-sliderWrapper {
    flex: 1 1 auto;
    min-width: 180px;
    width: min-content;
    max-width: 50%;
    align-self: center;
    right: 0;
    transform: scale(1);
    transition: 0.5s transform ease-in-out;

    &_expanded {
        padding-top: 146px;
        position: fixed;
        width: 100vw;
        max-width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        transform: scale(1);
        background-color: $bgc-overlay;
        z-index: 1;
    }

    &-content {

        &_expanded {
            max-width: $max-content-width;
            max-height: 90vh;
        }

        &_mobile_expanded {
            max-width: 348px;
        }
    }
}

.promo-tabs {
    width: max-content;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    padding: 14px 16px 6px;
    -webkit-border-radius: 26px;
    -moz-border-radius: 26px;
    border-radius: 26px;

    background-color: rgba(255, 255, 255, 0.178018);
    backdrop-filter: blur(54.3656px);
}