.headerNav {
    display: flex;
    align-items: center;
}

.headerNav-list {
    display: flex;
    align-items: center;
    gap: 32px;
    margin-left: 32px;

}

.headerNav-button {
    margin-left: 50px;
}
