@import "variables";

@font-face {
    font-family: 'Roboto Slab';
    src: url(./assets/fonts/RobotoSlab/RobotoSlab-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url(./assets/fonts/RobotoSlab/RobotoSlab-Black.ttf);
    font-weight: 900;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto/Roboto-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto/Roboto-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url(./assets/fonts/Roboto/Roboto-Bold.ttf);
    font-weight: 700;
}

html {
    scroll-behavior: smooth;
    min-width: 1000px;
}

body {
    margin: 0;
    min-width: 1000px;

    font-family: $ff-regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-family: $ff-title;
}

p {
    margin: 0;
    font-family: $ff-regular;
}

img {
    max-width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

ul, ol {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}

#root {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    scroll-behavior: smooth;

    box-sizing: border-box;
    min-height: 100vh;
    width: 100%;
}

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    height: $header-height;
    max-width: 100%;
}

.header-container_black {
    background-color: $bgc-dark;
}

.main-container {
    max-width: 100%;
}

.content-container {
    max-width: 100%;
    margin: 0 auto;
}

.footer-container {
    height: 220px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    padding-top: 60px;

    background-color: $bgc-dark;
}

.container {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    max-width: $max-content-width;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.header-logo {
    width: 185px;
    flex: 0 0 auto;
}

.content {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.75;
    color: $fc-dark;
    margin-top: $header-height;
    padding: 60px 0;
    margin-bottom: 15px;
}

.content-textBlock {
    margin-bottom: 20px;
}

.content-title {
    font-family: $ff-title;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 1.31;
    margin-bottom: 15px;
}

.content-subtitle {
    margin-bottom: 10px;

    font-family: $ff-regular;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.15;
    margin-bottom: 15px;
}

.content-text {
    font-family: $ff-regular;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.75;
    margin-bottom: 15px;

    &:not(&:last-child) {
        margin-bottom: 20px;
    }
}

.contactUs-promo {
    position: relative;
    width: 100%;
    height: 400px;

    background-image: url("../public/assets/bg/contact-us-bg.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $bgc-dark;
        opacity: 0.75;
    }
}

/* Стили для таблицы персональных данных */
.content .personal-data-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 1.75;
    margin-bottom: 15px;
}

.content .personal-data-table th,
.content .personal-data-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.content .personal-data-table th {
    background-color: #f2f2f2;
    text-align: left;
}

/* Стили для списков */
.content ol,
.content ul {
    padding-left: 20px;
    margin-bottom: 15px;
}

.content li {
    list-style: none;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;

    &::before {
        position: absolute;
        left: 0;
    }
}

.content ol ol,
.content ul ul {
    padding-left: 20px;
}

.content ol ul,
.content ul ol {
    padding-left: 20px;
}
